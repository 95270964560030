import React from "react"
import Layout from "../layouts/HomeLayout"
import SEO from "../components/seo"

import Header from "../components/Home/Header"

// const useStyles = makeStyles(theme => ({}))

const IndexPage = ({ data }) => {
  // const classes = useStyles()

  return (
    <Layout>
      <SEO title="Home" />
      <Header />
    </Layout>
  )
}

export default IndexPage
